@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 786px) {
  #morale {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
  }

  /* form {
    border:  1px solid #b1b7c3;
    border-radius: 8px;
    width: 96%;
    margin: 2%;
    padding: 8px;
    align-self: center;
  } */

  .form-question {
    margin: 8px 0;
  }
  
  .form-input {
    margin: 5px;
  }

  .form-rating {
    margin: 5%;
  }
  
  /* .datatable { */
    /* margin: 5%; */
    /* padding: 2%; */
    /* width: 98%; */
    /* border-top: 1px solid black; */
  /* } */
}

@media only screen 
and (min-width : 1224px) {


  #review {
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
  }

  .review-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  /* .form-section {
    width: 48%;
  } */

  form {
    border:  1px solid #b1b7c3;
    border-radius: 8px;
    padding: 2%;
    /* position: relative; */
    /* height: 70vh; */
  }

  .form-set {
    width: 100%;
  }

  .form-input {
    margin: 5px;
  }

  /* had to use ID since .form-select is a class in bootstrap */
  #reviewStaff {
    width: 20vw;
  }

  .form-text {
    width: 20vw;
  }

  .custom-label {
    margin-right: 15px;
    width: 100%;
    display: inline;
  }
  
  .form-label {
    margin-right: 15px;
  }

  .form-area {
    width: 100%;
    height: 6rem;
  }

  .form-submit {
    display: block;
    margin: 10px auto;
  }

  .data-section{
    width: 48%;
  }

  /* .datatable { */
    /* margin: 5%; */
    /* height: 70vh; */
    /* overflow-y: scroll; */
    /* border-top: 1px solid black; */
  /* } */

}

h5 {
  text-align: center;
  padding-bottom: 2rem;
}

fieldset {
  margin-top: 5px;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

.powerbi-report {
  margin: 0 auto;
  width: 95vw;
  height: 85vh;
}

.metric {
  padding: 5px;
}

/* .static-metric {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-left: 20%;
  padding-right: 20%;
} */

.card-title {
  font-weight: bold;
  margin: 0;
}

.card-value {
  margin: 0;
}

.card-target {
  margin: 0;

}

.show-alert {
  display: block;
  text-align: center;
  font-weight: 600;
  animation: fadeOut 5s linear 1 forwards;
}

.hide-alert {
  display: none;
  animation: fadeOut 5s linear 1 forwards;
}

.data-layout {
    width: 100%;
  }
  
  .data-charts {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .data-ar-cards {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 2em;
    padding-right: 2em;
  }
  
  .error-dialogue {
    position: absolute;
    background: slategray;
    color: rgb(255, 255, 255);
    padding: 6px 10px;
    border-radius: 5px 0 5px 5px;
  }
  
  .error-dialogue:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0;
    border-top: 8px solid slategray;
    border-right: 12px solid transparent;
  }