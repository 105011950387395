

.mywip-metric-card
    margin: 0
    box-shadow: none
    .p-card-body
        padding:0
    .p-card-content
        padding: 0
    .p-card-footer
        padding: 0

.border-right
    border-right: 2px solid gray
