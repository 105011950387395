@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700&display=swap");
:root {
  --primary-base: #6eba4e;
  --primary-darken: rgb(0, 91, 114);
  --primary-darkest: rgb(0,61,76);
  --primary-lighter: #6dd849;
  --brand-text-color: #63ab4a;
  --brand-text-color-light: #a0c295;
  --brand-text-hover: lighten(var(--brand-text-color), 20%);
  --border-color: #3e4c5a;
  --gradient-color-1: var(--primary-darkest);
  --gradient-color-2: rgba(66,142,75,1);
  --gradient-color-3: rgb(73, 193, 84);
  font-family: Lato, Helvetica, sans-serif;
  --font-family: Lato, Helvetica, sans-serif;
  --surface-a: #fefefe;
  --surface-b: #6cc24a;
  --surface-c: #003d4c;
  --surface-d: #001516;
  --surface-e: #ffffff;
  --surface-f: #f2682a;
  --text-color: rgb(4, 61, 117);
  --text-color-secondary: #708da9;
  --text-color-light-primary: #7daedb;
  --primary-color: #efefef;
  --primary-color-text: #ffffff;
  --surface-0: #ffffff;
  --surface-50: #f1f4f6;
  --surface-100: #e3eee5;
  --surface-200: #dbe6dd;
  --surface-300: #c9dfcd;
  --surface-400: #b0d4b9;
  --surface-500: #80b88e;
  --surface-600: #75a380;
  --surface-700: #435565;
  --surface-800: #2d3844;
  --surface-900: #161c22;
  --gray-50: #f1f4f6;
  --gray-100: #e2e8ee;
  --gray-200: #c6d1dd;
  --gray-300: #a9bbcb;
  --gray-400: #8da4ba;
  --gray-500: #708da9;
  --gray-600: #5a7187;
  --gray-700: #435565;
  --gray-800: #2d3844;
  --gray-900: #161c22;
  --green-500: #6dd849;
  --content-padding: 1.25rem;
  --inline-spacing: 0.5rem;
  --border-radius: 6px;
  --surface-ground: #eff3f8;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dfe7ef;
  --surface-hover: #f6f9fc;
  --focus-ring: 0 0 0 1px #c6b9fd;
  --maskbg: rgba(0, 0, 0, 0.4);
  --highlight-bg: #dfdcfc;
  --highlight-text-color: #7254f3;
  --primary-text-font: "Noto Sans Display", sans-serif;
  --primary-header-font: "Gabarito", sans-serif;
}

.help-button {
  margin-left: auto;
  background: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, 0);
  font-size: calc(0.25vw + 1rem) !important;
  font-family: var(--primary-header-font) !important;
  font-weight: 700;
  color: var(--surface-200) !important;
}
.help-button:hover {
  color: white !important;
  transition: 0.75s;
  text-shadow: var(--surface-200) 0px 0px 2px;
}

.help-email-button {
  float: right;
  font-size: calc(0.25vw + 0.75rem) !important;
  font-family: var(--primary-header-font) !important;
  font-weight: 600;
}

.help-button-root {
  box-shadow: none !important;
}

.help-dialog {
  max-width: 1000px;
}

.help-center-text {
  text-align: center;
}

.help-dialog-content {
  margin: 2.5%;
}
.help-dialog-content p {
  margin: auto;
  width: 95%;
  font-size: calc(0.35vw + 0.7rem) !important;
  font-family: var(--primary-text-font) !important;
}

.help-topic-header {
  margin: 1.5% 0 0.25% 0;
  font-size: calc(0.5vw + 0.75rem) !important;
  font-family: var(--primary-header-font) !important;
  font-weight: 700;
}

.help-dialog-header {
  font-size: calc(0.75vw + 1.15rem) !important;
  font-family: var(--primary-header-font) !important;
  font-weight: 700;
}/*# sourceMappingURL=help.css.map */