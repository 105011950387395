@use "./_variables"

.help-button
    margin-left: auto
    background: #ffffff00
    border-color: #ffffff00
    font-size: calc(.25vw + 1rem) !important
    font-family: var(--primary-header-font) !important
    font-weight: 700
    color: var(--surface-200) !important
    &:hover
        color: white !important
        transition: .75s
        text-shadow: var(--surface-200) 0px 0px 2px

.help-email-button
    float: right
    font-size: calc(.25vw + .75rem) !important
    font-family: var(--primary-header-font) !important
    font-weight: 600

.help-button-root
    box-shadow: none !important

.help-dialog
    max-width: 1000px

.help-center-text
    text-align: center

.help-dialog-content
    margin: 2.5%
    p
        margin: auto
        width: 95%
        font-size: calc(.35vw + .7rem) !important
        font-family: var(--primary-text-font) !important

.help-topic-header
    margin: 1.5% 0 .25% 0
    font-size: calc(.5vw + .75rem) !important
    font-family: var(--primary-header-font) !important
    font-weight: 700

.help-dialog-header
    font-size: calc(.75vw + 1.15rem) !important
    font-family: var(--primary-header-font) !important
    font-weight: 700
